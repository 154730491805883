<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import appConfig from "../../../../src/app.config";

export default {
  locales: {
    pt: {
      Order: "Pedido",
      "Payment Method": "Forma de Pagamento",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "Aguardando pagamento",
      "Payment confirmed": "Pagamento confirmado",

      Address: "Endereço",
      "Send exactly value to make payment":
        "Envie exatamente o valor para efetuar o pagamento",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",

      "Have a voucher?": "Possui um voucher?",
      Validate: "Validar",
      "Voucher is required.": "O voucher é obrigatório",
      "The voucher is invalid.": "O voucher é inválido.",
      "This voucher has already been used.": "Este voucher já foi utilizado.",

      "To validate your transaction, paste the transaction hash here":
        "Para validar sua transação, cole aqui a hash da transação",
      Send: "Enviar",

      "This order is not yours.": "Este pedido não é seu.",
      "XFB payment is not available for this order.":
        "O pagamento com XFB não está disponível para este pedido.",
      "The informed transaction hash has already been used.":
        "A hash de transação informada já foi utilizada.",
      "Transaction hash could not be registered, try again.":
        "Não foi possível registrar a hahs da transação, tente novamente.",

      "Click here to pay": "Clique aqui para pagar",
    },
    es: {
      Order: "Pedido",
      "Payment Method": "Forma de Pago",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "En espera de pago",
      "Payment confirmed": "Pago confirmado",

      Address: "Dirección",
      "Send exactly value to make payment":
        "Enviar exactamente el valor de hacer el pago",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",

      "Have a voucher?": "¿Tienes un cupón?",
      Validate: "Validar",
      "Voucher is required.": "Se requiere el cupón",
      "The voucher is invalid.": "El cupón no es válido.",
      "This voucher has already been used.": "Este cupón ya ha sido utilizado.",

      "To validate your transaction, paste the transaction hash here":
        "Para validar la transacción, pegar el hash transacción aquí",
      Send: "Enviar",

      "This order is not yours.": "Este pedido no es tuyo.",
      "XFB payment is not available for this order.":
        "XFB pago no está disponible para este fin.",
      "The informed transaction hash has already been used.":
        "El hash transacción informada ya se ha utilizado.",
      "Transaction hash could not be registered, try again.":
        "El hash de la transacción no se pudo registrar, intente nuevamente.",

      "Click here to pay": "Haga clic aquí para pagar",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      plans: null,

      order: {
        id: this.$route.params.id,
        voucher: false,
        date: null,

        subtotal: "0.00",
        discount: "0.00",
        shipping: {
          method: "",
          value: "0.00",
          address: {
            zipcode: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            city: "",
            state: "",
          },
        },
        payment: {
          methods: [],
          list: [],
        },

        total: "0.00",
        topay: "0.00",

        items: null,
        status: null,
      },

      voucher: {
        loading: false,
        alert: {
          type: "",
          message: "",
        },
        code: "",
      },

      document: {
        loading: false,
        status: false,
        id: null,
        title: null,
        file: null,
      },

      file: null,

      proof: {
        add: false,
        loading: false,
        errored: false,
        status: false,
        list: null,
      },
    };
  },
  validations: {
    voucher: {
      code: { required },
    },
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get("store/orders/" + this.order.id)
          .then((response) => {
            if (response.data.status == "success") {
              this.order = response.data.order;

              this.order.topay = parseFloat(response.data.order.total);
              response.data.order.payment.list.forEach((e) => {
                if (e.value > 0) {
                  this.order.topay -= parseFloat(e.value);
                }
              });

              this.loading = false;
            } else {
              this.empty = true;
              this.message = response.data.message;
            }
          })
          .catch((error) => {
            this.errored = error;
          });
      }
    },
    voucherSubmit() {
      if (this.voucher.code) {
        this.voucher.loading = true;

        api
          .post("wallet/order/voucher/" + this.order.id, {
            code: this.voucher.code,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.getOrder();
              this.voucher.loading = false;
            } else {
              this.voucher.alert.type = "alert-danger";
              this.voucher.alert.message = response.data.message;
              this.voucher.loading = false;
            }
          });
      }
    },
    getFile: function () {
      this.proof.loading = true;

      api
        .get("store/orders/file/" + this.order.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.proof.list = response.data.files;
          } else {
            this.proof.list = null;
          }
        })
        .catch((error) => {
          this.proof.errored = error;
          this.proof.loading = false;
        })
        .finally(() => {
          this.proof.loading = false;
        });
    },
    setFile() {
      this.document.loading = true;

      let formData = new FormData();

      formData.append("file", this.file);

      var config = {
        headers: {
          "x-auth-token": localStorage.token,
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(
          appConfig.apiUrl + "store/orders/file/" + this.order.id,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.getFile();
            this.$noty.success(response.data.message);
            this.document.status = false;
          } else {
            this.$noty.error(response.data.message);
            this.document.status = false;
          }
          this.document.loading = false;
        });
    },
    onFileSelected() {
      this.file = this.$refs.file[0].files[0];
    },
  },
  mounted() {
    this.getOrder();
    this.getFile();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Order") }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="empty">{{ t("No records found.") }}</div>
            <div v-else class="p-2">
              <div class="d-flex">
                <h4 class="mb-0 font-size-15 flex-fill">#{{ order.id }}</h4>
                <h4 class="mb-0 font-size-15 flex-fill text-right">
                  {{ order.date }}
                </h4>
              </div>
              <hr />
              <div class="table-responsive">
                <table class="table table-nowrap">
                  <thead>
                    <tr>
                      <th class="border-0 text-uppercase">{{ t("Item") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Price") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Qty") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Total") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td, index) in order.items" :key="index">
                      <td>{{ td.name }}</td>
                      <td>{{ td.price | currency }}</td>
                      <td>{{ td.quantity }}</td>
                      <td>{{ td.subtotal | currency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-5">
                <div class="col-lg-6 mb-4">
                  <div v-if="order.shipping.address.zipcode">
                    <h3
                      v-if="order.type == 'receive'"
                      class="font-size-16 mb-3"
                    >
                      {{ t("Endereço de entrega") }}
                    </h3>
                    <h3 v-else class="font-size-16 mb-3">
                      {{ t("Endereço de retirada") }}
                    </h3>
                    {{ order.shipping.address.address }},
                    {{ order.shipping.address.number }},
                    <span v-if="order.shipping.address.complement"
                      >{{ order.shipping.address.complement }},
                    </span>
                    {{ order.shipping.address.district }}<br />
                    {{ order.shipping.address.city }}/{{
                      order.shipping.address.state
                    }},
                    {{ order.shipping.address.zipcode }}
                  </div>
                  <a
                    v-if="
                      order.shipping.tracking && order.shipping.tracking.code
                    "
                    target="_blank"
                    :href="order.shipping.tracking.url"
                    class="btn btn-default btn-sm mt-1 p-2 align-middle"
                  >
                    <i
                      class="bx bxs-truck font-size-17 text-white mr-1 align-top"
                    ></i>
                    Rastrear pedido
                  </a>
                </div>
                <div class="col-lg-6 mb-4">
                  <h3 class="font-size-16 mb-3">
                    {{ t("Detalhes do pagamento") }}
                  </h3>
                  <div class="table-responsive">
                    <table class="table table-nowrap table-borderless">
                      <tbody>
                        <tr v-if="order.discount != '0.00'">
                          <td class="px-0 py-1">{{ t("VOUCHER") }}</td>
                          <td class="px-0 py-1 text-right">
                            - {{ order.discount | currency }}
                          </td>
                        </tr>
                        <tr v-if="order.subtotal != order.total">
                          <td class="px-0 py-1">{{ t("SUBTOTAL") }}</td>
                          <td class="px-0 py-1 text-right">
                            {{ order.subtotal | currency }}
                          </td>
                        </tr>
                        <tr v-if="order.shipping.value > 0">
                          <td class="px-0 py-1">
                            {{ t("FRETE") }} — {{ order.shipping.method }}
                          </td>
                          <td class="px-0 py-1 text-right">
                            + {{ order.shipping.value | currency }}
                          </td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1 font-weight-bold">
                            {{ t("TOTAL") }}
                          </td>
                          <td class="px-0 py-1 font-weight-bold text-right">
                            {{ order.total | currency }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h3 class="font-size-16 mb-2">{{ t("Status") }}</h3>
              <div v-for="(td, index) in order.status" :key="index">
                {{ td.date }} — {{ t(td.status) }}
              </div>
              <b-tabs
                v-if="order.payment.status === 'pending'"
                justified
                nav-class="nav-tabs-custom mt-5"
                content-class="p-3 text-muted"
              >
                <b-tab
                  v-for="(pay, method) in order.payment.methods"
                  :key="method"
                >
                  <template v-slot:title>{{ pay.name }}</template>
                  <div v-if="method == 'PIX'" class="text-center">
                    <img style="width: 250px" :src="pay.qrcode" />
                    <p>Leia o qrcode acima ou</p>
                    <ol class="text-left">
                      <li>Abra o app do seu banco ou seu app de pagamentos.</li>
                      <li>Busque a opção de pagar com pix.</li>
                      <li>Copie e cole o seguinte código.</li>
                      <li>
                        Após o pagamento envie o comprovante da tranferência.
                      </li>
                    </ol>

                    <p class="text-left">
                      A confirmação do pagamento pode demorar até 10 minutos
                      entre os horários das 07:00 as 00:00 horas, após este
                      período caso não receba seus tokens entrar em contato com
                      o suporte através de um ticket. Pagamentos realizados
                      entre 00:00 as 07:00 serão confirmados a partir das 07:00
                      horas.
                    </p>

                    <div class="border rounded p-3 mb-3 text-left">
                      {{ pay.copypaste }}
                    </div>

                    <button
                      class="btn btn-default"
                      v-clipboard:copy="pay.copypaste"
                    >
                      <i class="bx bx-copy font-size-18 align-middle"></i>
                      Copiar
                    </button>

                    <hr class="" />
                    <div class="mt-5 text-center">
                      <h3 class="font-size-16 mb-2">Comprovantes</h3>
                      <p>
                        Após realizar seu pagamento, anexe seu comprovante aqui.
                      </p>
                      <div
                        class=""
                        v-for="(prof, index) in proof.list"
                        :key="index"
                      >
                        <a target="_blank" :href="prof.url"
                          ><i class="bx bx-file h3 align-middle"></i>
                          {{
                            prof.url.replace(
                              "https://s3.ashows.com.br/store/",
                              ""
                            )
                          }}</a
                        >
                      </div>
                      <div class="text-center p-0">
                        <b-form @submit.prevent="setFile">
                          <div class="input-group">
                            <input
                              type="file"
                              id="file"
                              ref="file"
                              aria-describedby="inputGroupFileAddon04"
                              @change="onFileSelected"
                              aria-label="Upload"
                              class="form-control"
                            />
                            <b-button
                              class="border-left-0 px-4"
                              style="
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                              "
                              type="submit"
                              variant="default"
                              :disabled="document.loading"
                            >
                              {{ t("Send") }}
                              <b-spinner
                                v-if="document.loading"
                                small
                                class="ml-2 align-middle"
                                variant="white"
                                role="status"
                              ></b-spinner>
                            </b-button>
                          </div>
                        </b-form>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="method === 'BUSD'" class="text-center">
                    <img class="d-none" alt="" :src="pay.qrcode" /><br />
                    <p class="font-size-16 mt-0 mb-0">
                      <small class="text-uppercase">{{ t("Value") }}</small
                      ><br />
                      <strong>{{ pay.total }}</strong>
                      <button
                        class="btn btn-link p-0 ml-2"
                        v-clipboard:copy="pay.total"
                      >
                        <i class="bx bx-copy font-size-18 text-default"></i>
                      </button>
                    </p>
                    <p class="font-size-16 mt-2">
                      <small class="text-uppercase">{{ t("Address") }}</small
                      ><br />
                      <strong>{{ pay.address }}</strong>
                      <button
                        class="btn btn-link p-0 ml-2"
                        v-clipboard:copy="pay.address"
                      >
                        <i class="bx bx-copy font-size-18 text-default"></i>
                      </button>
                    </p>
                    <p>
                      {{ t("Send exactly value to make payment") }}
                      <small v-if="pay.expiration"
                        ><br />Expiration in {{ pay.expiration }}</small
                      >
                    </p>
                    <ol class="text-left">
                      <li>Abra sua carteira Metamask/TrustWallet</li>
                      <li>Escolha a opção "USDT BEP20"</li>
                      <li>Escolha a opção "enviar"</li>
                      <li>Copie e cole o endereço</li>
                      <li>Copie e cole o valor</li>
                      <li>Escolha a opção "seguinte"</li>
                      <li>Escolhe a opção "confirmar"</li>
                    </ol>

                    <p class="text-left">
                      A confirmação do pagamento pode demorar até 1 hora para
                      ser realizada pelo sistema, após este período caso não
                      receba seus tokens entrar em contato com o suporte através
                      de um ticket.
                    </p>

                    <p
                      v-if="pay.description"
                      class="text-left font-size-14 font-weight-bold mb-0"
                    >
                      {{ pay.description }}
                    </p>

                    <hr class="" />
                    <div class="mt-5 text-center">
                      <h3 class="font-size-16 mb-2">Comprovantes</h3>
                      <p>
                        Após realizar seu pagamento, envie o comprovante para o WhatsApp
                      </p>
                      <p><a class="btn btn-success" href="https://api.whatsapp.com/send/?phone=5527999746581">Enviar Comprovante</a></p>
                    </div>
                  </div>
                  <div v-else class="text-center">
                    <img
                      class="mt-1 mb-4"
                      height="50px"
                      src="@/assets/images/logo-mercado-pago.svg"
                    /><br />
                    <a
                      target="_blank"
                      class="btn btn-default btn-lg"
                      :href="pay.url"
                      >{{ t("Click here to pay") }}</a
                    >
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
